import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _bd43ac82 = () => interopDefault(import('../pages/airdrop-calendar/index.vue' /* webpackChunkName: "pages/airdrop-calendar/index" */))
const _1c4468ea = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _3b50f980 = () => interopDefault(import('../pages/banks/index.vue' /* webpackChunkName: "pages/banks/index" */))
const _478f56ff = () => interopDefault(import('../pages/biggest-gainers.vue' /* webpackChunkName: "pages/biggest-gainers" */))
const _17f0135c = () => interopDefault(import('../pages/cefi/index.vue' /* webpackChunkName: "pages/cefi/index" */))
const _1960d16b = () => interopDefault(import('../pages/crt.vue' /* webpackChunkName: "pages/crt" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5434801e = () => interopDefault(import('../pages/defi/index.vue' /* webpackChunkName: "pages/defi/index" */))
const _e1ee378e = () => interopDefault(import('../pages/exchanges/index.vue' /* webpackChunkName: "pages/exchanges/index" */))
const _41d65b9f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _554ad60d = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _a3eb88ea = () => interopDefault(import('../pages/glossary/index.vue' /* webpackChunkName: "pages/glossary/index" */))
const _45702dc3 = () => interopDefault(import('../pages/ico-events-calendar/index.vue' /* webpackChunkName: "pages/ico-events-calendar/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c9d63a76 = () => interopDefault(import('../pages/nft/index.vue' /* webpackChunkName: "pages/nft/index" */))
const _b1b77bde = () => interopDefault(import('../pages/nft-drop-calendar.vue' /* webpackChunkName: "pages/nft-drop-calendar" */))
const _6e0c712e = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _88b0ca04 = () => interopDefault(import('../pages/play-to-earn/index.vue' /* webpackChunkName: "pages/play-to-earn/index" */))
const _730a0ec8 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _1f1b35f6 = () => interopDefault(import('../pages/recently-added.vue' /* webpackChunkName: "pages/recently-added" */))
const _a17d832c = () => interopDefault(import('../pages/recover.vue' /* webpackChunkName: "pages/recover" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1c176355 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _c3c5cef2 = () => interopDefault(import('../pages/tokens/index.vue' /* webpackChunkName: "pages/tokens/index" */))
const _a3af6ae2 = () => interopDefault(import('../pages/trending.vue' /* webpackChunkName: "pages/trending" */))
const _4ea0743d = () => interopDefault(import('../pages/account/ad-cabinet/index.vue' /* webpackChunkName: "pages/account/ad-cabinet/index" */))
const _703bafe9 = () => interopDefault(import('../pages/account/airdrops/index.vue' /* webpackChunkName: "pages/account/airdrops/index" */))
const _7bc9cd89 = () => interopDefault(import('../pages/account/crt.vue' /* webpackChunkName: "pages/account/crt" */))
const _869f7c08 = () => interopDefault(import('../pages/account/ico/index.vue' /* webpackChunkName: "pages/account/ico/index" */))
const _af49060c = () => interopDefault(import('../pages/account/nft-drops/index.vue' /* webpackChunkName: "pages/account/nft-drops/index" */))
const _0f54666c = () => interopDefault(import('../pages/account/p2e/index.vue' /* webpackChunkName: "pages/account/p2e/index" */))
const _57cfd822 = () => interopDefault(import('../pages/account/portfolio/index.vue' /* webpackChunkName: "pages/account/portfolio/index" */))
const _07382da2 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _24bcaef3 = () => interopDefault(import('../pages/exchanges/derivatives.vue' /* webpackChunkName: "pages/exchanges/derivatives" */))
const _7eeb8684 = () => interopDefault(import('../pages/exchanges/dex.vue' /* webpackChunkName: "pages/exchanges/dex" */))
const _63eb508c = () => interopDefault(import('../pages/exchanges/lending.vue' /* webpackChunkName: "pages/exchanges/lending" */))
const _308c9d4c = () => interopDefault(import('../pages/account/ad-cabinet/add.vue' /* webpackChunkName: "pages/account/ad-cabinet/add" */))
const _25e6e016 = () => interopDefault(import('../pages/account/ad-cabinet/banners/index.vue' /* webpackChunkName: "pages/account/ad-cabinet/banners/index" */))
const _fa901ad8 = () => interopDefault(import('../pages/account/ad-cabinet/distributors/index.vue' /* webpackChunkName: "pages/account/ad-cabinet/distributors/index" */))
const _2cdad3f8 = () => interopDefault(import('../pages/account/airdrops/add.vue' /* webpackChunkName: "pages/account/airdrops/add" */))
const _093a626a = () => interopDefault(import('../pages/account/ico/add.vue' /* webpackChunkName: "pages/account/ico/add" */))
const _25fcb149 = () => interopDefault(import('../pages/account/nft-drops/add.vue' /* webpackChunkName: "pages/account/nft-drops/add" */))
const _fe2e95ce = () => interopDefault(import('../pages/account/p2e/add.vue' /* webpackChunkName: "pages/account/p2e/add" */))
const _a326fd04 = () => interopDefault(import('../pages/account/portfolio/add.vue' /* webpackChunkName: "pages/account/portfolio/add" */))
const _078771f8 = () => interopDefault(import('../pages/account/ad-cabinet/banners/add.vue' /* webpackChunkName: "pages/account/ad-cabinet/banners/add" */))
const _504dc563 = () => interopDefault(import('../pages/account/ad-cabinet/distributors/add.vue' /* webpackChunkName: "pages/account/ad-cabinet/distributors/add" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ca0fe8c6 = () => interopDefault(import('../pages/account/ad-cabinet/banners/_id.vue' /* webpackChunkName: "pages/account/ad-cabinet/banners/_id" */))
const _206da37f = () => interopDefault(import('../pages/account/ad-cabinet/distributors/_id/index.vue' /* webpackChunkName: "pages/account/ad-cabinet/distributors/_id/index" */))
const _8646d826 = () => interopDefault(import('../pages/account/ad-cabinet/distributors/_id/edit.vue' /* webpackChunkName: "pages/account/ad-cabinet/distributors/_id/edit" */))
const _616f3c36 = () => interopDefault(import('../pages/account/ad-cabinet/_id.vue' /* webpackChunkName: "pages/account/ad-cabinet/_id" */))
const _68d2cede = () => interopDefault(import('../pages/account/airdrops/_id.vue' /* webpackChunkName: "pages/account/airdrops/_id" */))
const _cbc2d938 = () => interopDefault(import('../pages/account/ico/_id.vue' /* webpackChunkName: "pages/account/ico/_id" */))
const _768f143c = () => interopDefault(import('../pages/account/nft-drops/_id.vue' /* webpackChunkName: "pages/account/nft-drops/_id" */))
const _1fa479b2 = () => interopDefault(import('../pages/account/p2e/_id.vue' /* webpackChunkName: "pages/account/p2e/_id" */))
const _4d284617 = () => interopDefault(import('../pages/account/portfolio/_id.vue' /* webpackChunkName: "pages/account/portfolio/_id" */))
const _c0b0ef12 = () => interopDefault(import('../pages/airdrop-calendar/_slug.vue' /* webpackChunkName: "pages/airdrop-calendar/_slug" */))
const _1a8dc7a2 = () => interopDefault(import('../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _399a5838 = () => interopDefault(import('../pages/banks/_slug.vue' /* webpackChunkName: "pages/banks/_slug" */))
const _1b5d55ec = () => interopDefault(import('../pages/cefi/_slug.vue' /* webpackChunkName: "pages/cefi/_slug" */))
const _57a1c2ae = () => interopDefault(import('../pages/defi/_slug.vue' /* webpackChunkName: "pages/defi/_slug" */))
const _e55b7a1e = () => interopDefault(import('../pages/exchanges/_slug.vue' /* webpackChunkName: "pages/exchanges/_slug" */))
const _401fba57 = () => interopDefault(import('../pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _a758cb7a = () => interopDefault(import('../pages/glossary/_slug.vue' /* webpackChunkName: "pages/glossary/_slug" */))
const _43b98c7b = () => interopDefault(import('../pages/ico-events-calendar/_slug.vue' /* webpackChunkName: "pages/ico-events-calendar/_slug" */))
const _7b8d3092 = () => interopDefault(import('../pages/nft/_address.vue' /* webpackChunkName: "pages/nft/_address" */))
const _8c1e0c94 = () => interopDefault(import('../pages/play-to-earn/_slug.vue' /* webpackChunkName: "pages/play-to-earn/_slug" */))
const _c7331182 = () => interopDefault(import('../pages/tokens/_slug.vue' /* webpackChunkName: "pages/tokens/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    name: "about___en"
  }, {
    path: "/account",
    component: _cbd4fa14,
    name: "account___en"
  }, {
    path: "/airdrop-calendar",
    component: _bd43ac82,
    name: "airdrop-calendar___en"
  }, {
    path: "/articles",
    component: _1c4468ea,
    name: "articles___en"
  }, {
    path: "/banks",
    component: _3b50f980,
    name: "banks___en"
  }, {
    path: "/biggest-gainers",
    component: _478f56ff,
    name: "biggest-gainers___en"
  }, {
    path: "/cefi",
    component: _17f0135c,
    name: "cefi___en"
  }, {
    path: "/crt",
    component: _1960d16b,
    name: "crt___en"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard___en"
  }, {
    path: "/defi",
    component: _5434801e,
    name: "defi___en"
  }, {
    path: "/exchanges",
    component: _e1ee378e,
    name: "exchanges___en"
  }, {
    path: "/faq",
    component: _41d65b9f,
    name: "faq___en"
  }, {
    path: "/forgot",
    component: _554ad60d,
    name: "forgot___en"
  }, {
    path: "/glossary",
    component: _a3eb88ea,
    name: "glossary___en"
  }, {
    path: "/ico-events-calendar",
    component: _45702dc3,
    name: "ico-events-calendar___en"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/nft",
    component: _c9d63a76,
    name: "nft___en"
  }, {
    path: "/nft-drop-calendar",
    component: _b1b77bde,
    name: "nft-drop-calendar___en"
  }, {
    path: "/offline",
    component: _6e0c712e,
    name: "offline___en"
  }, {
    path: "/play-to-earn",
    component: _88b0ca04,
    name: "play-to-earn___en"
  }, {
    path: "/policy",
    component: _730a0ec8,
    name: "policy___en"
  }, {
    path: "/recently-added",
    component: _1f1b35f6,
    name: "recently-added___en"
  }, {
    path: "/recover",
    component: _a17d832c,
    name: "recover___en"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/support",
    component: _1c176355,
    name: "support___en"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms___en"
  }, {
    path: "/tokens",
    component: _c3c5cef2,
    name: "tokens___en"
  }, {
    path: "/trending",
    component: _a3af6ae2,
    name: "trending___en"
  }, {
    path: "/account/ad-cabinet",
    component: _4ea0743d,
    name: "account-ad-cabinet___en"
  }, {
    path: "/account/airdrops",
    component: _703bafe9,
    name: "account-airdrops___en"
  }, {
    path: "/account/crt",
    component: _7bc9cd89,
    name: "account-crt___en"
  }, {
    path: "/account/ico",
    component: _869f7c08,
    name: "account-ico___en"
  }, {
    path: "/account/nft-drops",
    component: _af49060c,
    name: "account-nft-drops___en"
  }, {
    path: "/account/p2e",
    component: _0f54666c,
    name: "account-p2e___en"
  }, {
    path: "/account/portfolio",
    component: _57cfd822,
    name: "account-portfolio___en"
  }, {
    path: "/account/settings",
    component: _07382da2,
    name: "account-settings___en"
  }, {
    path: "/exchanges/derivatives",
    component: _24bcaef3,
    name: "exchanges-derivatives___en"
  }, {
    path: "/exchanges/dex",
    component: _7eeb8684,
    name: "exchanges-dex___en"
  }, {
    path: "/exchanges/lending",
    component: _63eb508c,
    name: "exchanges-lending___en"
  }, {
    path: "/account/ad-cabinet/add",
    component: _308c9d4c,
    name: "account-ad-cabinet-add___en"
  }, {
    path: "/account/ad-cabinet/banners",
    component: _25e6e016,
    name: "account-ad-cabinet-banners___en"
  }, {
    path: "/account/ad-cabinet/distributors",
    component: _fa901ad8,
    name: "account-ad-cabinet-distributors___en"
  }, {
    path: "/account/airdrops/add",
    component: _2cdad3f8,
    name: "account-airdrops-add___en"
  }, {
    path: "/account/ico/add",
    component: _093a626a,
    name: "account-ico-add___en"
  }, {
    path: "/account/nft-drops/add",
    component: _25fcb149,
    name: "account-nft-drops-add___en"
  }, {
    path: "/account/p2e/add",
    component: _fe2e95ce,
    name: "account-p2e-add___en"
  }, {
    path: "/account/portfolio/add",
    component: _a326fd04,
    name: "account-portfolio-add___en"
  }, {
    path: "/account/ad-cabinet/banners/add",
    component: _078771f8,
    name: "account-ad-cabinet-banners-add___en"
  }, {
    path: "/account/ad-cabinet/distributors/add",
    component: _504dc563,
    name: "account-ad-cabinet-distributors-add___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/account/ad-cabinet/banners/:id",
    component: _ca0fe8c6,
    name: "account-ad-cabinet-banners-id___en"
  }, {
    path: "/account/ad-cabinet/distributors/:id",
    component: _206da37f,
    name: "account-ad-cabinet-distributors-id___en"
  }, {
    path: "/account/ad-cabinet/distributors/:id/edit",
    component: _8646d826,
    name: "account-ad-cabinet-distributors-id-edit___en"
  }, {
    path: "/account/ad-cabinet/:id?",
    component: _616f3c36,
    name: "account-ad-cabinet-id___en"
  }, {
    path: "/account/airdrops/:id",
    component: _68d2cede,
    name: "account-airdrops-id___en"
  }, {
    path: "/account/ico/:id",
    component: _cbc2d938,
    name: "account-ico-id___en"
  }, {
    path: "/account/nft-drops/:id?",
    component: _768f143c,
    name: "account-nft-drops-id___en"
  }, {
    path: "/account/p2e/:id",
    component: _1fa479b2,
    name: "account-p2e-id___en"
  }, {
    path: "/account/portfolio/:id",
    component: _4d284617,
    name: "account-portfolio-id___en"
  }, {
    path: "/airdrop-calendar/:slug?",
    component: _c0b0ef12,
    name: "airdrop-calendar-slug___en"
  }, {
    path: "/articles/:slug",
    component: _1a8dc7a2,
    name: "articles-slug___en"
  }, {
    path: "/banks/:slug",
    component: _399a5838,
    name: "banks-slug___en"
  }, {
    path: "/cefi/:slug",
    component: _1b5d55ec,
    name: "cefi-slug___en"
  }, {
    path: "/defi/:slug",
    component: _57a1c2ae,
    name: "defi-slug___en"
  }, {
    path: "/exchanges/:slug",
    component: _e55b7a1e,
    name: "exchanges-slug___en"
  }, {
    path: "/faq/:slug",
    component: _401fba57,
    name: "faq-slug___en"
  }, {
    path: "/glossary/:slug",
    component: _a758cb7a,
    name: "glossary-slug___en"
  }, {
    path: "/ico-events-calendar/:slug?",
    component: _43b98c7b,
    name: "ico-events-calendar-slug___en"
  }, {
    path: "/nft/:address",
    component: _7b8d3092,
    name: "nft-address___en"
  }, {
    path: "/play-to-earn/:slug?",
    component: _8c1e0c94,
    name: "play-to-earn-slug___en"
  }, {
    path: "/tokens/:slug",
    component: _c7331182,
    name: "tokens-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
